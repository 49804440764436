.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  background-color: #43587789 !important;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ReactModal__Content--after-open {
  border-radius: 0px !important;
  border: none !important;
  box-shadow: 0px -10px 10px #43587741 !important;
  /* top: 20% !important; */
  left: 0px !important;
  right: 0px !important;
  bottom: 0px !important;
  flex-direction: column;
  display: flex;
}

.react-notification-root {
  position: fixed;
  z-index: 999999;
}

.notification-container-mobile-top {
  top: 0px !important;
  width: 100% !important;
  right: 0px !important;
  left: 0px !important;
}

.notification-container-top-center {
  top: 0px !important;
  width: 100% !important;
}

.notification-container-mobile-bottom {
  bottom: 0px !important;
  width: 100% !important;
  right: 0px !important;
  left: 0px !important;
}

.notification-item {
  border-radius: 0px !important;
  margin-bottom: 0px !important;
  margin-top: 15px;
  padding: 10px 10px;
  border: none;
}

.notification-danger,
.notification-danger .timer {
  background-color: #BE0000;
}

.notification-default,
.notification-default .timer {
  background-color: #435877;
}

.notification-success,
.notification-success .timer {
background-color: #00C689;
}

/* Animation */
.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 500ms ease-in;
}

@keyframes slideLeftOut {
  0% { left: 0 }
  100% { left: -100% }
}

@keyframes slideLeftIn {
  0% { left: 100% }
  100% { left: 0 }
}

@keyframes slideRightOut {
  0% { left: 0 }
  100% { left: 100% }
}

@keyframes slideRightIn {
  0% { left: -100% }
  100% { left: 0 }
}

.slideLeft-exit-active {
  animation: slideLeftOut 500ms both ease;
}
.slideLeft-enter-active {
  animation: slideLeftIn 500ms both ease;
}

.slideRight-exit-active {
  animation: slideRightOut 500ms both ease;
}
.slideRight-enter-active {
  animation: slideRightIn 500ms both ease;
}

@keyframes hiddenDelayed {
  0% { opacity: 1 }
  100% { opacity: 0 }
}

.hidden-with-delay {
  animation: 1s linear 2s both hiddenDelayed;
}

.animate {
  transition: transform .5s ease;
}

.slide-up {
  transform: translateY(-100%)
}

/* end animation */

.sticky {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
}

.pending-0 {
  background: linear-gradient(
    45deg,
    white 25%,
    #f5f7fc 25%,
    #f5f7fc 50%,
    white 50%,
    white 75%,
    #f5f7fc 75%
  );
  background-size: 15px 15px;
}

.pending-1 {
  background-image: linear-gradient(45deg, 
  hsl(0, 0%, 100%) 40%, 
  #e4e4e4 40%,
  #e4e4e4 50%,
  #ffffff 50%,
  #ffffff 90%,
  #e4e4e4 90%,
  #e4e4e4 100%);
  background-size: 25px 25px;
}

.pending-2 {
  background: linear-gradient(
    45deg,
    #9eabba 25%,
    #b3c0e040 25%,
    #b3c0e040 50%,
    #9eabba 50%,
    #9eabba 75%,
    #b3c0e040 75%
  );
  background-size: 15px 15px;
}

.shadow {
  box-shadow: 0px 3px 15px #06009621;
}

.listContainer {
  width: 100%;
}

#container-circles {
  display: none;
}

/* .react-swipeable-view-container {
  transform: translate(33%, 0px);
} */

/* start - checkbox */
.checkbox-label {
  display: block;
  border: 0.5px solid #9eabba;
  background-color: #ffffff;
  box-shadow: none;
  border-radius: 5px;
  width: 20px;
  height: 20px;
}

input[type="checkbox"] {
  display: none;
}

input[type="checkbox"]:checked + .checkbox-label {
  background-color: #0082de;
  box-shadow: none;
  border: none;
}

input[type="checkbox"]:checked + .checkbox-label:after {
  content: "\2713";
  color: white;
  padding-left: 3px;
}

/* end - checkbox */

/* start radio peer */

.radio-valid:checked ~ label.valid {
  --text-opacity: 1;
  --bg-opacity: 1;
  color: rgba(56, 161, 105, var(--text-opacity));
  background-color: rgba(240, 255, 244, var(--bg-opacity));
}

.radio-approuved:checked ~ label.approuved {
  --text-opacity: 1;
  --bg-opacity: 1;
  color: rgba(221, 107, 32, var(--text-opacity));
  background-color: rgba(255, 250, 240, var(--bg-opacity));
}

.radio-refused:checked ~ label.refused {
  --text-opacity: 1;
  --bg-opacity: 1;
  color: rgba(229, 62, 62, var(--text-opacity));
  background-color: rgba(255, 245, 245, var(--bg-opacity));
}

/* end radio peer */
